import Image from 'next/image';

export function Logo({ short = false }: ILogoProps) {
  if (short) {
    return (
      <Image
        src='/images/favicon.png'
        width={32}
        height={32}
        alt='dynzup z logo'
      />
    );
  }

  return (
    <Image
      src='/images/logo.svg'
      width={110}
      height={32}
      alt='dynzup logo'
      className='object-contain'
      priority
    />
  );
}
