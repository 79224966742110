import React, { useContext } from 'react';

import { Text } from '@/app/components/Typography';
import { AlertIcon } from '@/icons/AlertIcon';
import { IconLamp } from '@/icons/IconLamp';
import { IconXRound } from '@/icons/IconXRound';
import { SuccessCircleIcon } from '@/icons/SucessCircleIcon';

import { InfoRootContext } from '../InfoRoot';
import { TInfoType } from '../InfoRoot/ types';
import { infoClasses } from '../InfoRoot/styles';

const IconObj: { [key in TInfoType]: React.ReactNode } = {
  warn: <AlertIcon className='h-4 w-4 !stroke-yellow-700' />,
  error: <IconXRound className='!h-4 !w-4 !stroke-red-700 ' />,
  success: <SuccessCircleIcon className='h-4 w-4 stroke-green-700' />,
  info: <IconLamp className='h-4 w-4 !stroke-blue-700' />,
};

export function InfoTitle({ title, className, icon: Icon }: TInfoTitleProps) {
  const { type } = useContext(InfoRootContext);
  const { title: titleClasses } = infoClasses({ type });

  const IconComponent = Icon ? (
    <Icon className='h-5 w-5 !stroke-blue-700' />
  ) : (
    IconObj[type]
  );

  return (
    <Text className={titleClasses({ className })}>
      {IconComponent}
      {title}
    </Text>
  );
}
