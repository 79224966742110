import { iconClasses } from '../styles';

function IconLamp({ className, ...props }: IconProps) {
  return (
    <svg
      className={iconClasses({ className })}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M5.49728 12.0002H5.99749'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 5.49742V5.99763'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.39808 7.39826L7.75823 7.75841'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.6747 14.9243H10.3253'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.1672 14.7703C9.03524 13.972 8.55064 12.5325 8.96939 11.2121C9.38815 9.89174 10.6138 8.99463 11.999 8.99463C13.3842 8.99463 14.6099 9.89174 15.0286 11.2121C15.4474 12.5325 14.9628 13.972 13.8308 14.7703C13.6825 14.8692 13.5921 15.0344 13.5887 15.2125V16.6781C13.5887 17.0294 13.4491 17.3663 13.2007 17.6147C12.9523 17.8631 12.6154 18.0027 12.2641 18.0027H11.7339C11.3826 18.0027 11.0457 17.8631 10.7973 17.6147C10.5489 17.3663 10.4093 17.0294 10.4093 16.6781V15.2155C10.4072 15.0362 10.3166 14.8696 10.1672 14.7703V14.7703Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 21.0038V21.0038C7.02736 21.0038 2.99625 16.9727 2.99625 12.0001V12.0001C2.99625 7.02745 7.02736 2.99634 12 2.99634V2.99634C16.9726 2.99634 21.0037 7.02745 21.0037 12.0001V12.0001C21.0037 16.9727 16.9726 21.0038 12 21.0038V21.0038Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.0025 12.0002H18.5027'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.2418 7.75841L16.6019 7.39826'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export { IconLamp };
