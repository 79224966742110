import { forwardRef, useContext } from 'react';

import { Button } from '../../Button';
import { InfoRootContext } from '../InfoRoot';
import { infoClasses } from '../InfoRoot/styles';

import { TButtonPlusProps } from './types';

const InfoAction = forwardRef<HTMLButtonElement, TButtonPlusProps>(
  ({ className, children, ...props }, ref) => {
    const { type } = useContext(InfoRootContext);
    const { button, loading } = infoClasses({ type });

    return (
      <Button
        className={button({ className })}
        loadingClassName={loading()}
        ref={ref}
        {...props}
      >
        {children}
      </Button>
    );
  },
);

InfoAction.displayName = 'Button';

export { InfoAction };
