'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { set } from 'lodash';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { signIn } from 'next-auth/react';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  IFormSignInProps,
  SignInFormData,
} from '@/app/(organization)/auth/components/Authentication/components/FormSignInOrganization/types';
import { OrganizationAuthForms } from '@/app/(organization)/auth/enums';
import { signinZod } from '@/app/(organization)/auth/zodSchema';
import { Button } from '@/app/components/Button';
import CardInfo from '@/app/components/CardInfo';
import Input from '@/app/components/Input';
import { ModalContext } from '@/app/components/Modal/Root';

export function FormSignInOrganization({ redirectSamePath }: IFormSignInProps) {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const isSignInRoute = pathname === '/auth/signin';

  const [hasError, setHasError] = useState(false);
  const { toggle } = useContext(ModalContext);

  const methods = useForm<SignInFormData>({
    resolver: zodResolver(signinZod),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting, isSubmitSuccessful },
  } = methods;

  const watchedEmail = watch('email');
  const watchedPassword = watch('password');

  useEffect(() => {
    if (watchedEmail || watchedPassword) {
      setHasError(false);
      if (window !== undefined) {
        window.history.replaceState({}, '', pathname);
      }
    }
  }, [pathname, watchedEmail, watchedPassword]);

  useEffect(() => {
    if (searchParams.get('error')) {
      setHasError(true);
    }
  }, [searchParams]);

  const onSubmit = async (data: SignInFormData) => {
    const callbackUrl = searchParams.get('callbackUrl') || '/dashboard';
    const search = new URLSearchParams(window.location.search);
    const pathAndSearch =
      pathname + (search.toString() ? `?${search.toString()}` : '');
    try {
      const response = await signIn('credentials', {
        email: data.email,
        password: data.password,
        redirect: true,
        callbackUrl:
          redirectSamePath && !isSignInRoute ? pathAndSearch : callbackUrl,
      });

      if (response?.ok && !response?.error) {
        toggle?.();
      }

      if (response?.error) {
        setHasError(true);
      }
    } catch (e) {
      console.error('error', e);
      setHasError(true);
      console.log(e);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        id={OrganizationAuthForms.SIGN_IN}
        className='mb-1 flex w-full min-w-full flex-col px-10'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input.Root>
          <Input.Label>Email</Input.Label>
          <Input.Field formNoValidate {...register('email')} type='email' />
          <Input.Error fieldName='email' />
        </Input.Root>

        <Input.Root>
          <span className='flex items-center justify-between'>
            <Input.Label>Password</Input.Label>
            <Link
              href='/auth/recover'
              className='-mt-4 self-end text-xs text-purpleBlue-700 hover:font-semibold'
            >
              Forgot your password?
            </Link>
          </span>
          <Input.Password {...register('password')} />
          <Input.Error fieldName='password' />
        </Input.Root>

        {hasError && (
          <CardInfo.Root className='mb-4' type='error'>
            <CardInfo.Description description='Login credentials are not valid' />
          </CardInfo.Root>
        )}

        <Button
          type='submit'
          defaultLoading={isSubmitting && !hasError}
          disabled={isSubmitting || (isSubmitSuccessful && !hasError)}
        >
          Sign In
        </Button>
      </form>
    </FormProvider>
  );
}
