'use client';

import { createContext } from 'react';

import { IInfoRootProps, TContextProps } from './ types';
import { infoClasses } from './styles';

export const InfoRootContext = createContext<TContextProps>({
  type: 'warn',
});

export function InfoRoot({
  children,
  className,
  type = 'warn',
}: IInfoRootProps) {
  const { base } = infoClasses({ type });
  return (
    <InfoRootContext.Provider
      value={{
        type,
      }}
    >
      <div className={base({ className })}>{children}</div>
    </InfoRootContext.Provider>
  );
}
