import { tv } from 'tailwind-variants';

export const classes = tv({
  base: 'text-current text-sm font-regular !font-normal',
  variants: {
    type: {
      warn: 'text-yellow-700',
      error: 'text-red-700',
      success: 'text-green-700',
      info: 'text-blue-700',
    },
  },
});
