import { tv } from 'tailwind-variants';

export const infoClasses = tv({
  slots: {
    base: 'p-4 rounded-md text-sm font-semibold items-center gap-4 w-full flex justify-between',
    button: 'bg-white',
    title: 'text-current text-sm font-semibold flex gap-2 items-center',
    loading: '',
  },
  variants: {
    type: {
      warn: {
        base: 'bg-yellow-50 text-yellow-600',
        button:
          'text-yellow-600 hover:bg-white hover:shadow-sm hover:shadow-yellow-400 focus:ring-yellow-600',
        loading: 'stroke-yellow-600',
      },
      error: {
        base: 'bg-red-50 text-red-600',
        button: 'text-red-600 hover:bg-white focus:ring-red-600',
        loading: 'stroke-red-600',
      },
      success: {
        base: 'bg-green-50 text-green-600',
        button: 'text-green-600',
        loading: 'stroke-green-600',
      },
      info: {
        base: 'bg-blue-50 text-blue-600 border-blue-100 border',
        button: 'text-blue-600',
        loading: 'stroke-blue-600',
      },
    },
  },
  defaultVariants: {
    type: 'warn',
  },
});
