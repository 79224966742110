'use client';

import { useContext } from 'react';

import { Text } from '@/app/components/Typography';

import { InfoRootContext } from '../InfoRoot';

import { classes } from './styles';

type InfoDescriptionProps = {
  description: string;
  className?: string;
};

export function InfoDescription({
  description,
  className,
}: InfoDescriptionProps) {
  const { type } = useContext(InfoRootContext);
  return <Text className={classes({ className, type })}>{description}</Text>;
}
