'use client';

import { createContext, useCallback, useEffect, useState } from 'react';

export const ModalContext = createContext<IModalContext>({
  open: false,
  toggle: () => null,
  setOpen: () => null,
});

export function ModalRoot({ children, defaultOpen }: IModalRootProps) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (defaultOpen !== undefined) setOpen(defaultOpen);
  }, [defaultOpen]);

  const toggle = useCallback(() => setOpen(prev => !prev), []);

  return (
    <ModalContext.Provider value={{ toggle, open, setOpen }}>
      {children}
    </ModalContext.Provider>
  );
}
