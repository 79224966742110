import { InfoAction } from './InfoAction';
import { InfoDescription } from './InfoDescription';
import { InfoRoot } from './InfoRoot';
import { InfoTitle } from './InfoTitle';

const CardInfo = {
  Root: InfoRoot,
  Action: InfoAction,
  Title: InfoTitle,
  Description: InfoDescription,
};

export default CardInfo;
