'use client';

import { useRouter } from 'next/dist/client/components/navigation';

import { FormSignInOrganization } from '@/app/(organization)/auth/components/Authentication/components/FormSignInOrganization';
import { MessageAuthGuest } from '@/app/(organization)/auth/components/Authentication/components/Message';
import { formWrapperClasses } from '@/app/(organization)/auth/components/Authentication/styles';
import { OrganizationAuthForms } from '@/app/(organization)/auth/enums';
import { Logo } from '@/app/components/Logo';
import { Heading, Text } from '@/app/components/Typography';

export function FormSignIN({ redirectSamePath = true }: IFormSigninProps) {
  const router = useRouter();

  return (
    <div className='flex w-full sm:w-[600px] flex-col gap-4 rounded-md border bg-white p-6 transition-all sm:p-10'>
      <Logo />

      <div>
        <Heading as='h2'>Getting into your account</Heading>
        <Text size='xs' className='text-blueGray-500'>
          Enter your credentials to continue
        </Text>
      </div>

      <div className={formWrapperClasses()}>
        <FormSignInOrganization redirectSamePath={redirectSamePath} />
      </div>

      <div className='-mx-10 flex overflow-hidden'>
        <MessageAuthGuest
          onClick={() => router.push('/auth/signup')}
          message='New here?'
          callToAction='Create an account!'
          form={OrganizationAuthForms.SIGN_UP}
        />
      </div>
    </div>
  );
}
